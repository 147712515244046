.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image {
  width: calc(33.33% - 20px);
  margin: 10px;
  max-width: 300px;
  object-fit: contain;
  cursor: pointer;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.modalImage {
  max-width: 80%;
  max-height: 100%;
  height: auto;
  border-radius: 8px;
}

.nextPrevImgButton {
  color: white !important;
  height: 100%;
}
