html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-attachment: fixed;
}

.main {}